import React from "react";
import "./AboutSectionOne.css";

export const AboutSectionOne = () => {
  return (
    <div className="AboutSectionOne-wrapper container">
      <p className="about-title-text">About us</p>
    </div>
  );
};
export default AboutSectionOne;
