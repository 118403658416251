import React, { useState, useEffect, useMemo } from "react";
import { firestore } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const Counter = ({ storageKey, start, increment, interval }) => {
  const [count, setCount] = useState(start);

  // Memoize the Firestore document reference to prevent re-creation on re-renders
  const docRef = useMemo(
    () => doc(firestore, "counters", storageKey),
    [storageKey]
  );

  useEffect(() => {
    const fetchCounter = async () => {
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCount(docSnap.data().value);
        } else {
          await setDoc(docRef, { value: start });
        }
      } catch (error) {
        console.error("Error fetching counter:", error);
      }
    };
    fetchCounter();
  }, [docRef, start]);

  useEffect(() => {
    const timer = setInterval(async () => {
      setCount((prev) => {
        const newValue = prev + increment;
        setDoc(docRef, { value: newValue }).catch((error) =>
          console.error("Error updating counter:", error)
        );
        return newValue;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [increment, interval, docRef]);

  return <span>{count.toLocaleString()}</span>;
};

export default Counter;

// // export default Counter;
// import React, { useState, useEffect } from "react";

// const Counter = ({ storageKey, start, increment, interval }) => {
//   const [count, setCount] = useState(() => {
//     const savedCount = localStorage.getItem(storageKey);
//     return savedCount ? parseInt(savedCount, 10) : start;
//   });

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCount((prev) => {
//         const newValue = prev + increment;
//         localStorage.setItem(storageKey, newValue); // Save to localStorage
//         return newValue;
//       });
//     }, interval);

//     return () => clearInterval(timer); // Cleanup interval on unmount
//   }, [increment, interval, storageKey]);

//   return <span>{count.toLocaleString()}</span>;
// };
// export default Counter;
// // import React, { useState, useEffect } from "react";

// // const Counter = ({ targetNumber, duration, style }) => {
// //   const [number, setNumber] = useState(0);

// //   useEffect(() => {
// //     let start = 0;
// //     const interval = duration / targetNumber; // Time between increments
// //     const timer = setInterval(() => {
// //       start += 1;
// //       setNumber(start);
// //       if (start === targetNumber) clearInterval(timer);
// //     }, interval);

// //     return () => clearInterval(timer); // Cleanup on unmount
// //   }, [targetNumber, duration]);

// //   return <div style={style}>{number}</div>;
// // };
