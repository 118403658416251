import React from "react";
import { motion } from "framer-motion";
import "./engagement.css";
import rewardImg from "../../assets/Engagment/rewardImg.svg";
import rewardSvg from "../../assets/Engagment/rewardSvg.svg";
import rewardBox from "../../assets/Engagment/rewardBox.png";
import rank from "../../assets/AISection/Ranks_with bg.png";
import rangSvg from "../../assets/Engagment/rank.svg";
import collectionSvg from "../../assets/Engagment/collection.svg";
import collectionImg from "../../assets/Engagment/Participate_Earn_cards.png";
// import collectionImg from "../../assets/Engagment/Cards.png";

export const EngagementSection = () => {
  return (
    <motion.div
      className="engagment-wrapper container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      {/* Title & Subtitle */}
      <motion.div
        className="engagment-title-subtitle"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <h2 className="engagment-title">
          Gamified Engagement for Creators and Communities
        </h2>
        <p className="engagment-subtitle">
          At 3VO, every action matters, and every voice counts.
        </p>
      </motion.div>

      {/* Cards Wrapper */}
      <motion.div
        className="engagment-cards-wrapper"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: { staggerChildren: 0.3 },
          },
        }}
      >
        {/* Rewards Card */}
        <motion.div
          className="engagment-cards"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          whileHover={{ scale: 1.05 }}
        >
          <img src={rewardImg} alt="" className="engagment-cards-image" />
          <div className="engagment-card-container">
            <div className="engagment-card-title-icon">
              <h4 className="engagment-card-title">Rewards</h4>
              <div className="engagment-card-icon-wrapper">
                <img src={rewardSvg} alt="" className="engagment-card-icon" />
              </div>
            </div>
            <div className="engagment-card-box-wrapper">
              <img src={rewardBox} alt="" className="engagment-card-box-1" />
            </div>
          </div>
        </motion.div>

        {/* Ranks Card */}
        <motion.div
          className="engagment-cards"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          whileHover={{ scale: 1.05 }}
        >
          <img src={rewardImg} alt="" className="engagment-cards-image" />
          <div className="engagment-card-container">
            <div className="engagment-card-title-icon">
              <h4 className="engagment-card-title">Ranks</h4>
              <div className="engagment-card-icon-wrapper">
                <img src={rangSvg} alt="" className="engagment-card-icon" />
              </div>
            </div>
            <div className="engagment-card-box-wrapper">
              <img src={rank} alt="" className="engagment-card-box-2" />
            </div>
          </div>
        </motion.div>

        {/* Collections Card */}
        <motion.div
          className="engagment-cards"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          whileHover={{ scale: 1.05 }}
        >
          <img src={rewardImg} alt="" className="engagment-cards-image" />
          <div className="engagment-card-container">
            <div className="engagment-card-title-icon">
              <h4 className="engagment-card-title">Collections</h4>
              <div className="engagment-card-icon-wrapper">
                <img
                  src={collectionSvg}
                  alt=""
                  className="engagment-card-icon"
                />
              </div>
            </div>
            <div className="engagment-card-box-wrapper">
              <img
                loading="lazy"
                src={collectionImg}
                alt=""
                className="engagment-card-box-3"
              />
            </div>
          </div>
        </motion.div>
      </motion.div>

      {/* Bottom Section */}

      <motion.div
        className="engagment-botton"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
      >
        <p className="botton-paragraph">
          Level up your community engagement with 3VO Quests. Creators can
          design custom challenges, rewarding participants with experience
          points, badges, collectible cards, and more.
          <br />
          Ignite friendly competition, boost content discovery, and build a
          loyal following by gamifying the experience and turning your community
          into a dedicated army.
          <div className="voutionaries-btn-sign">
            <a href="/newfeature">
              <div className="voutionaries-text-wrapper-cta">
                Meet AI 3VOlutionaries
              </div>
            </a>
          </div>
        </p>

        {/* Call to Action */}
        <motion.div
          className="engagment-cta"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <a href="https://t.me/mysteryBox3voBot">
            <p className="engagment-cta-text">Start Now</p>
          </a>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
