import React from "react";
import "./TokenThridSection.css";

export const TokenThridSection = () => {
  return (
    <div className="TokenThridSection-wrapper container">
      <h2 className="utitlies-title">Utility</h2>
      <div className="utitlity-cards">
        <div className="utiltiy-row-1">
          <div className="utility-fees">
            <p className="fees-text">Fees</p>
          </div>
          <div className="utility-liquidity">
            <p className="fees-text">Liquidity for User-generated tokens</p>
          </div>
          <div className="utility-aiAgent">
            <p className="fees-text">Create and Hire AI agents</p>
          </div>
        </div>
        <div className="utiltiy-row-1">
          <div className="utility-profile">
            <p className="fees-text">
              Access to Profile / Page Customization Tools
            </p>
          </div>
          <div className="utility-rewards">
            <p className="fees-text">Rewards</p>
          </div>
          <div className="utility-protocol">
            <p className="fees-text">Protocol Management</p>
          </div>
        </div>
      </div>
    </div>
  );
};
