import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../components/BrandSection/brandSection.css";
import offers from "../../assets/brandsection/discount-shape.svg";
import Whishlist from "../../assets/brandsection/heart-add.svg";
import Quests from "../../assets/brandsection/message-question.svg";
import Maps from "../../assets/brandsection/map21.svg";
import community from "../../assets/brandsection/community.svg";
import Media from "../../assets/brandsection/media.svg";
import Shop from "../../assets/brandsection/shop.svg";
import Wall from "../../assets/brandsection/wall.svg";
import Events from "../../assets/brandsection/events.svg";
import Portfolio from "../../assets/brandsection/portfolio.svg";
import { AnimatedTitle } from "../myUitls/AnimatedTitle.js";
import { AnimatedText } from "../myUitls/AnimatedText.js";
export const BrandSection = () => {
  return (
    <div className="brandSection">
      <div className="fifthFrame">
        <div className="table-content">
          <div className="bg-class">
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={offers} alt="offers" className="offers-icon" />
                <p className="offers-text">Offers</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={Media} alt="offers" className="offers-icon" />
                <p className="offers-text">Media</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={Whishlist} alt="offers" className="offers-icon" />
                <p className="offers-text">Whishlist</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={Shop} alt="offers" className="offers-icon" />
                <p className="offers-text">Shop</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={Quests} alt="offers" className="offers-icon" />
                <p className="offers-text">Quests</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={Wall} alt="offers" className="offers-icon" />
                <p className="offers-text">Wall</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={Maps} alt="offers" className="offers-icon" />
                <p className="offers-text">AI Chats</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={Events} alt="offers" className="offers-icon" />
                <p className="offers-text">Events</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={community} alt="offers" className="offers-icon" />
                <p className="offers-text"> Coummunity</p>
              </div>
            </Link>
            <Link to="/newfeature?category=Customization#ftCards">
              <div className="offers">
                <img src={Portfolio} alt="offers" className="offers-icon" />
                <p className="offers-text">Portfolio</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="brand-contnet-wrapper">
          <div className="brand-title">
            <AnimatedTitle>Build your ultimate page</AnimatedTitle>
          </div>
          <div className="brand-paragraph">
            <p className="m-text">
              With modular features and integrated token utility, create truly
              unique pages for your followers and communities from a library of
              interactive apps. From social quests and token-gated exclusive
              offers to merch shops and events, 3VO gives you the tools to build
              a thriving ecosystem and monetize in new powerful ways.
            </p>
            <AnimatedText>
              With modular features and integrated token utility, create truly
              unique pages for your followers and communities from a library of
              interactive apps. From social quests and token-gated exclusive
              offers to merch shops and events, 3VO gives you the tools to build
              a thriving ecosystem and monetize in new powerful ways.
            </AnimatedText>
          </div>
        </div>
      </div>
    </div>
  );
};
