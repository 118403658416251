import React from "react";
import "./3voToken.css";
import coinBase from "../../assets/svg/coinbase.svg";
import eth from "../../assets/svg/Eth.svg";
import binance from "../../assets/svg/Binance.svg";
import illustration from "../../assets/svg/Illustartion.png";
export const Tokenomics = () => {
  return (
    <div className="Tokenomics-section">
      <div className="Tokenomics-3voToken">
        <p className="Tokennomics-title">$3VO Token</p>
        <div className="Tokennomics-cards-buttons">
          <div className="Tokennomics-cards">
            <div className="Tokennomics-card-inner">
              <p className="Tokennomics-card-title">Ticker</p>
              <p className="Tokennomics-card-number">$3VO</p>
            </div>
            <div className="Tokennomics-card-inner">
              <p className="Tokennomics-card-title">FDV</p>
              <p className="Tokennomics-card-number">$7M</p>
            </div>
            <div className="Tokennomics-card-inner">
              <p className="Tokennomics-card-title">Total Supply</p>
              <p className="Tokennomics-card-number">1B</p>
            </div>
            <div className="Tokennomics-card-inner-icons">
              <p className="Tokennomics-card-title">Network</p>
              <div className="Tokennomics-card-network">
                <img
                  src={coinBase}
                  alt="coinBase"
                  className="network-coin-icon"
                />
                <img src={eth} alt="coinBase" className="network-coin-icon-e" />
                <img
                  src={binance}
                  alt="coinBase"
                  className="network-coin-icon-b"
                />
              </div>
            </div>
          </div>
          <div className="Tokennomics-buttons">
            <div className="Tokennomics-token-btn">
              <a
                href="https://forms.gle/mo8inCjMv8LDAtY3A"
                target="_blank"
                rel="noreferrer"
              >
                <p className="Tokennomics-token-btn-text">Buy $3VO</p>
              </a>
            </div>

            <div className="Tokennomics-2">
              <a href="/newtoken">
                <div className="Tokennomics-wrapper-cta">Tokenomics</div>
              </a>
            </div>
          </div>
          <div className="Tokennomics-buttons-m">
            <div className="Tokennomics-btn-sign">
              <a
                href="https://forms.gle/mo8inCjMv8LDAtY3A"
                target="_blank"
                rel="noreferrer"
              >
                <div className="Tokennomics-text-wrapper-cta">Buy $3VO</div>
              </a>
            </div>
            <div className="Tokennomics-btn-sign-2">
              <a href="/newtoken">
                <div className="Tokennomics-text-wrapper-cta">Tokenomics</div>
              </a>
            </div>
            {/* <div className="Tokennomics-token-btn">
              <p className="Tokennomics-token-btn-text">Buy $3VO</p>
            </div>
            <div className="Tokennomics-2">
              <div className="Tokennomics-wrapper-cta">Tokenomics</div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="Tokenomics-illustration">
        <img
          src={illustration}
          alt="coinBase"
          loading="lazy"
          className="illustration-map"
        />
      </div>
    </div>
  );
};
