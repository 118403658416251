import React from "react";
import "./SectionFiveToken.css";
import burn from "../../../../assets/svg/burn.svg";
import Buyback from "../../../../assets/svg/Icon_buyback.svg";
import staking from "../../../../assets/svg/staking.svg";
import voting from "../../../../assets/svg/voting.svg";
export const SectionFiveToken = () => {
  return (
    <div className="TokenFiveSection-wrapper container">
      <h2 className="TokenFiveSection-title">
        Deflationary dynamics for a sustainable ecosystem
      </h2>
      <div className="TokenFive-Eco-Cards">
        <div className="TokenFive-card-container">
          <div className="token-card-icon">
            <img src={burn} alt="dao" className="token-card-svg" />
          </div>
          <div className="token-card-title-body">
            <p className="token-card-title">Burn</p>
            <p className="token-card-sub">
              3VO tokens are burned via the DAO. Tokens are burned via a vote.
            </p>
          </div>
        </div>

        <div className="TokenFive-card-container">
          <div className="token-card-icon">
            <img src={Buyback} alt="dao" className="token-card-svg" />
          </div>
          <div className="token-card-title-body">
            <p className="token-card-title">Buyback</p>
            <p className="token-card-sub">
              3VO tokens are bought back from the market based on DAO vote
            </p>
          </div>
        </div>

        <div className="TokenFive-card-container">
          <div className="token-card-icon">
            <img src={staking} alt="dao" className="token-card-svg" />
          </div>
          <div className="token-card-title-body">
            <p className="token-card-title">Staking</p>
            <p className="token-card-sub">
              Token holders can hold their 3VO tokens within the DAO to gain
              access to community rewards
            </p>
          </div>
        </div>

        <div className="TokenFive-card-container">
          <div className="token-card-icon">
            <img src={voting} alt="dao" className="token-card-svg" />
          </div>
          <div className="token-card-title-body">
            <p className="token-card-title">Voting</p>
            <p className="token-card-sub">
              Burning also occurs of each vote costs a certain amount of tokens
              to be burned.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
