import React from "react";
import "./TokenSecondSection.css";

export const TokenSecondSection = () => {
  return (
    <div className="TokenSecondSection-wrapper container">
      <div className="Utility-title-body">
        <h2 className="Utility-title">Utility: Fueling the 3VOlution</h2>
        <p className="Utility-sub">
          In the age of AI and decentralized technologies, the 3VO token unlocks
          a new era of online interaction. As the native currency of the 3VO
          ecosystem, it empowers users to: The 3VO token is not just a currency;
          it's a key that unlocks the full potential of the decentralized
          internet.
        </p>
      </div>
      <div className="utility-key-metrics">
        <div className="key-matrics-table">
          <h5 className="key-matrics-title">Key Metrics</h5>
          <div className="matrics-table">
            <div className="matrics-left">
              <div className="matrics-line">
                <p className="matrics-line-left">Ticker</p>
                <p className="matrics-line-right">$3VO</p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left">Project Token Valuation</p>
                <p className="matrics-line-right">$7,000,000</p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left">Tokens For Sale</p>
                <p className="matrics-line-right">35%</p>
              </div>
            </div>
            <div className="matrics-right">
              <div className="matrics-line">
                <p className="matrics-line-left">Blockchain Network</p>
                <p className="matrics-line-right">BNB,Base,Eth</p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left">Token Supply</p>
                <p className="matrics-line-right">1,000,000,000</p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left">
                  Tokens for Sale <span>(total)</span>
                </p>
                <p className="matrics-line-right">350,000,000</p>
              </div>
            </div>
          </div>
        </div>
        <div className="key-matrics-table">
          <h5 className="key-matrics-title">Detailed Token Metrics</h5>
          <div className="matrics-table-2   ">
            <div className="matrics-left">
              <div className="matrics-line">
                <p className="matrics-line-left">Token Type</p>
                <p className="matrics-line-right">ERC-20, BEP-20</p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left">Total Supply</p>
                <p className="matrics-line-right">1,000,000,000</p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left">Token Price</p>
                <p className="matrics-line-right">$0.007</p>
              </div>
            </div>
            <div className="matrics-right-2">
              <div className="matrics-line">
                <p className="matrics-line-left">
                  Initial Market Cap <span>(Without Liquidity)</span>
                </p>
                <p className="matrics-line-right">$282,800</p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left">Fully Diluted Market Cap</p>
                <p className="matrics-line-right">$7,000,000</p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left"> </p>
                <p className="matrics-line-right"></p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left"> </p>
                <p className="matrics-line-right"></p>
              </div>
              <div className="matrics-line">
                <p className="matrics-line-left"> </p>
                <p className="matrics-line-right"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
