import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./tradeToken.css";
import coinToken from "../../assets/svg/tokens-bg.png";
import createbtn from "../../assets/svg/create-token-large.svg";

export const TradeToken = () => {
  return (
    <div className="trade-token-wrapper container">
      <div className="trade-token-container">
        <div className="token-title-text">
          <img
            src={coinToken}
            alt="coins"
            loading="lazy"
            className="mobile-coins-bg"
          />
          <p className="token-title">Create and trade your tokens</p>
          <p className="token-text">
            Creators can easily issue and trade Memecoins and Fan Tokens with
            real-world utility within their communities.
          </p>
          <Link to="/newfeature" onClick={() => window.scrollTo(0, 0)}>
            <div className="create-token-btn">
              <p className="create-token-btn-text">Create Token</p>
            </div>
          </Link>
          <Link to="/newtoken" onClick={() => window.scrollTo(0, 0)}>
            <img src={createbtn} alt="coins" className="mobile-createbtn" />
          </Link>
          {/* <div className="create-token-btn-m">
            <p className="create-token-btn-text">Create Token</p>
          </div> */}
        </div>
        <AnimatedBtn>
          <div className="tader-group"></div>
          {/* animation */}
          <div className="tader-frame">
            <div className="tader-frame-image" loading="lazy"></div>
          </div>
        </AnimatedBtn>
      </div>
    </div>
  );
};
const AnimatedBtn = ({ children }) => {
  const textRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing once visible
          }
        });
      },
      { threshold: 0.05 } // Trigger when 10% of the paragraph is visible
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <p ref={textRef} className={`btn-wrapper ${isVisible ? "visible" : ""}`}>
      {children}
    </p>
  );
};
