import React from "react";
import "./BridgingSection.css";

export const BridgingSection = () => {
  return (
    <div className="bridging-firt-frame container">
      <div className="bridging-second-reactangle">
        <p className="bridging-title">
          Redefining the Future of Digital Communities
        </p>
        <p className="bridging-pargraph">
          3VO provides an integrated ecosystem for content monetization,
          eliminating fragmentation and streamlining operations. Its AI-driven
          automation enhances content management, community engagement, and
          scalability, enabling platforms to deliver seamless user experiences
          effortlessly. <br />
          <br />
          With advanced personalization and all-in-one solutions — such as token
          launchpad, AI content creation, marketplaces, community chats, and
          multi-chain wallet — anyone can build thriving communities without
          relying on disjointed tools. Interactive and rewarding engagement
          methods keep users invested, transforming passive audiences into
          active participants and driving long-term loyalty in an era of endless
          digital content.
        </p>
      </div>
      <div className="bridging-first-reactangle"></div>
    </div>
  );
};
export default BridgingSection;
