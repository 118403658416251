import React from "react";
import "./TokenFourSection.css";
import tokenMecganics from "../../../../assets/svg/Token-Mechanics.svg";
export const TokenFourSection = () => {
  return (
    <div className="TokenFourSection-wrapper container">
      <h2 className="Token-Mechanics-title">Token Mechanics</h2>
      <img
        src={tokenMecganics}
        alt="3vo-token-mechanics"
        className="img-token-mechanics"
      />
    </div>
  );
};
