import React from "react";
import "./TeamSection.css";
// import image from "../../../../assets/svg/member-image.png";
import ayman from "../../../../assets/NewTeam/ayman1.webp";
import wiktor from "../../../../assets/NewTeam/wiktor1.webp";
import moe from "../../../../assets/NewTeam/mo1.webp";
import alex from "../../../../assets/NewTeam/alex1.webp";
import Saif from "../../../../assets/NewTeam/saif1.webp";
import Mikhail from "../../../../assets/NewTeam/mikhail1.webp";
import Dmitry from "../../../../assets/NewTeam/dmitry1.webp";
import chris from "../../../../assets/NewTeam/chris1.webp";
import Cory from "../../../../assets/NewTeam/cory1.webp";
import Parker from "../../../../assets/NewTeam/parker1.webp";
import raluca from "../../../../assets/NewTeam/raluca1.webp";
import youniss from "../../../../assets/NewTeam/younissnew1.webp";
import twitterSvg from "../../../../assets/svg/member-link-x.svg";
import inSvg from "../../../../assets/svg/member-link-in.svg";
export const TeamSection = () => {
  return (
    <div className="team-section container">
      <h2 className="team-title">Team</h2>
      <div className="all-team">
        <div className="team-one">
          <div className="team-member">
            <img
              src={ayman}
              loading="lazy"
              alt="team-member"
              className="team-member-image"
            />
            <div className="member-name-position">
              <p className="member-name">Ayman Sayed</p>
              <p className="member-position">Founder of 3VO</p>
              <p className="member-about">
                Serial entrepreneur with 10+ years in crypto, third-time Web3
                founder. Expertise in wallet solutions, e-commerce, and digital
                banking.
              </p>
              <div className="member-links">
                <a href="https://x.com/aymanonline">
                  <img
                    src={twitterSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
                <a href="https://www.linkedin.com/in/aymanonline/">
                  <img
                    src={inSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="team-member">
            <img
              src={wiktor}
              alt="team-member"
              loading="lazy"
              className="team-member-image"
            />
            <div className="member-name-position">
              <p className="member-name">Wiktor Grzyb</p>
              <p className="member-position">Operations</p>
              <p className="member-about">
                3+ years in DAO management, previously Developer DAO (30k+ Web3
                devs) operator and consultant for Steward & Bankless Consulting.
                Founded the first Polish DAO.
              </p>
              <div className="member-links">
                <a href="https://x.com/Wikist_">
                  <img
                    src={twitterSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
                <a href="https://www.linkedin.com/in/wiktorgrzyb/">
                  <img
                    src={inSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="team-member">
            <img
              src={moe}
              loading="lazy"
              alt="team-member"
              className="team-member-image"
            />
            <div className="member-name-position">
              <p className="member-name">Mohammed Saad</p>
              <p className="member-position">Growth</p>
              <p className="member-about">
                Architect and developer with 4+ years in blockchain and Web3
                projects. Last project reached a $1B market cap.
              </p>
              <div className="member-links">
                <a href="https://x.com/OG_Mo_7">
                  <img
                    src={twitterSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
                <a href="https://www.linkedin.com/in/og-mohd/">
                  <img
                    src={inSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="team-member">
            <img
              src={alex}
              loading="lazy"
              alt="team-member"
              className="team-member-image"
            />
            <div className="member-name-position">
              <p className="member-name">Alex Belov</p>
              <p className="member-position">Information & Security</p>
              <p className="member-about">
                25+ years in IT as a developer, system administrator, system
                architect, and cybersecurity expert.
              </p>
              <div className="member-links">
                {/* <a href="https://www.linkedin.com/in/roosso/">
                  <img
                    src={twitterSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a> */}
                <a href="https://www.linkedin.com/in/roosso/">
                  <img
                    src={inSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="team-one">
          <div className="team-member">
            <img
              src={Saif}
              loading="lazy"
              alt="team-member"
              className="team-member-image"
            />
            <div className="member-name-position">
              <p className="member-name">Saif Amer</p>
              <p className="member-position">Business Development</p>
              <p className="member-about">
                Head of partnerships for Mario Nawfal’s IBC fund, advisor to the
                PIF Saudi Fund (one of the largest globally), and contributor to
                Web3 projects like zkPass.
              </p>
              <div className="member-links">
                <a href="https://x.com/saifamerr">
                  <img
                    src={twitterSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
                <a href="https://www.linkedin.com/in/saifamer/">
                  <img
                    src={inSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="team-member">
            <img
              loading="lazy"
              src={Mikhail}
              alt="team-member"
              className="team-member-image"
            />
            <div className="member-name-position">
              <p className="member-name">Mikhail Zhbankov</p>
              <p className="member-position">Technology</p>
              <p className="member-about">
                17+ years in tech development, including 8+ years as Chief
                Developer. Skilled in PHP, Python, Node.js, and Golang
              </p>
              <div className="member-links">
                {/* <a href="https://www.linkedin.com/in/mikhail-zhbankov-11515a195/">
                  <img
                    src={twitterSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a> */}
                <a href="https://www.linkedin.com/in/mikhail-zhbankov-11515a195/">
                  <img
                    src={inSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="team-member">
            <img
              loading="lazy"
              src={Dmitry}
              alt="team-member"
              className="team-member-image"
            />
            <div className="member-name-position">
              <p className="member-name">Dmitry Dyachenko</p>
              <p className="member-position">Mobile Development</p>
              <p className="member-about">
                13+ years developing mobile apps for iOS and Android, including
                4+ years in wallets and payments
              </p>
              <div className="member-links">
                <a href="https://www.linkedin.com/in/ddnproger/">
                  <img
                    src={twitterSvg}
                    alt="team-member"
                    className="team-member-link"
                  />
                </a>
                {/* <img
                  src={inSvg}
                  alt="team-member"
                  className="team-member-link"
                /> */}
              </div>
            </div>
          </div>
          <div className="team-member">
            <img
              src={chris}
              loading="lazy"
              alt="team-member"
              className="team-member-image"
            />
            <div className="member-name-position">
              <p className="member-name">Chris Orza</p>
              <p className="member-position">Fundraising</p>
              <p className="member-about">
                Senior BD Manager & Investor Relations at IBC Group. Hosts the
                largest show on X (15M+ weekly listeners)
              </p>
              <div className="member-links">
                {/* <img
                  src={twitterSvg}
                  alt="team-member"
                  className="team-member-link"
                />
                <img
                  src={inSvg}
                  alt="team-member"
                  className="team-member-link"
                /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="team-three-wrapper">
          <div className="team-three-members">
            <img
              src={Cory}
              alt="team-member"
              loading="lazy"
              className="team-member-image-3"
            />
            <div className="team-member-name-position-3">
              <p className="member-name">Cory Loflin</p>
              <p className="member-position">BD/Communications</p>
              <p className="member-about-1">
                Bachelors of Science in Electrical Engineering from University
                of Texas at Austin. 10+ years in product development at IBM and
                Dell. Founded a hospitality/event production company.
              </p>
            </div>
            <div className="member-links">
              <a href="https://x.com/unorigcory">
                <img
                  src={twitterSvg}
                  alt="team-member"
                  className="team-member-link"
                />
              </a>
              <a href="https://www.linkedin.com/in/cory-loflin">
                <img
                  src={inSvg}
                  alt="team-member"
                  className="team-member-link"
                />
              </a>
            </div>
          </div>
          <div className="team-three-members">
            <img
              loading="lazy"
              src={Parker}
              alt="team-member"
              className="team-member-image-3"
            />
            <div className="team-member-name-position-3">
              <p className="member-name">Parker Tagtmeier</p>
              <p className="member-position">Communications</p>
              <p className="member-about-1">
                2nd-time Web3 founder with 3+ years in crypto marketing. First
                project reached a $25M market cap in 2 weeks with 10k+ holders.
              </p>
            </div>
            <div className="member-links">
              <a href="https://x.com/ParkerDefi">
                <img
                  src={twitterSvg}
                  alt="team-member"
                  className="team-member-link"
                />
              </a>
              <a href="https://www.linkedin.com/in/parkertag/">
                <img
                  src={inSvg}
                  alt="team-member"
                  className="team-member-link"
                />
              </a>
            </div>
          </div>
          <div className="team-three-members">
            {/* <div className="team-three-member-image"> */}
            <img
              loading="lazy"
              src={raluca}
              alt="team-member"
              className="team-member-image-3"
            />
            <div className="team-member-name-position-3">
              <p className="member-name">Raluca Gabrielal</p>
              <p className="member-position">Community</p>
              <p className="member-about-1">
                Telegram community moderator with 4+ years of experience,
                skilled in community management, content creation, and graphic
                design for social media growth and engagement.
              </p>
            </div>
            <div className="member-links">
              <a href="https://x.com/Raluca_rrrh">
                <img
                  src={twitterSvg}
                  alt="team-member"
                  className="team-member-link"
                />
              </a>
              {/* <a href="https://www.linkedin.com/in/youniss-jaafil/">
                <img
                  src={inSvg}
                  alt="team-member"
                  className="team-member-link"
                />
              </a> */}
            </div>
          </div>
          <div className="team-three-members">
            {/* <div className="team-three-member-image"> */}
            <img
              loading="lazy"
              src={youniss}
              alt="team-member"
              className="team-member-image-3"
            />
            <div className="team-member-name-position-3">
              <p className="member-name">Youniss Jaafil</p>
              <p className="member-position">Frontend Engineer</p>
              <p className="member-about-1">
                2+ years of experience in web development, and delivering
                scalable, efficient, and user-centric software solutions. Strong
                focus on performance optimization and Web3 integration.
              </p>
            </div>
            <div className="member-links">
              {/* <img
                src={twitterSvg}
                alt="team-member"
                className="team-member-link"
              /> */}
              <a href="https://www.linkedin.com/in/youniss-jaafil/">
                <img
                  src={inSvg}
                  alt="team-member"
                  className="team-member-link"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
export default TeamSection;
