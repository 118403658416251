import React, { useState } from "react";
import "./faqSection.css";
import minusIcon from "../../assets/faq/minus.svg";
import plusIcon from "../../assets/faq/plus.svg";

export const FaqSection = () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How do I get $3VO token?",
      answer:
        "$3VO tokens will be available to buy via public launchpads before TGE—details on participating launchpads will be announced soon. Make sure to follow our official channels to stay updated and avoid scams. ",
    },
    {
      question: "Is 3VO live?",
      answer:
        "Yes, 3VO is live in beta access at app.3vo.me. New features are being added to the platform nearly every week, so you can expect constant improvements. The full release is expected by spring 2025—stay tuned for the big launch!",
    },
    {
      question: "How to sign up?",
      answer:
        "Signing up is easy! Visit 3vo.me, click the Launch App button, and then select Sign Up to create your account. All you need is an email address, phone number and optional wallet connection to get started. Join now and explore the beta version of 3VO!",
    },
    {
      question: "Is there KYC?",
      answer:
        "Yes, KYC (Know Your Customer) will be required for certain features, such as purchasing $3VO tokens or accessing specific platform functionalities, but it's not necessary to start using the platform. This ensures a secure and compliant environment for all users.",
    },
    {
      question: "How do I become a creator on 3VO?",
      answer:
        "Becoming a creator is simple! Once you’ve signed up, set up your Smart Profile and start uploading your content. You’ll also have the option to request Creator Status, which unlocks additional features like tipping and other monetization tools. 3VO is built to help you turn your creativity into income—no gatekeepers, no begging for likes.",
    },
    {
      question: "When is TGE?",
      answer:
        "The Token Generation Event (TGE) is coming soon! We’ll announce the exact date on our official channels, so make sure you’re following us to stay in the loop. Don’t miss your chance to be part of the revolution.",
    },
    {
      question: "Where can I find help?",
      answer:
        "Need assistance? Join our official Telegram channel, where our community managers are ready to answer your questions and provide support. You can also connect with other users and creators in the community for tips and advice.",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="faq-title">FAQ</div>
        <div className="faq-section">
          <div className="faq-cards">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`faq-card ${
                  openFaqIndex === index ? "faq-card-open" : "faq-card-closed"
                }`}
              >
                <div
                  className="faq-card-header"
                  onClick={() => toggleFaq(index)}
                >
                  <p className="faq-card-paragraph">{faq.question}</p>
                  <div className="faq-btn-wrapper">
                    <img
                      src={openFaqIndex === index ? minusIcon : plusIcon}
                      alt="toggle"
                      className="faq-btn"
                    />
                  </div>
                </div>
                {openFaqIndex === index && (
                  <p className="faq-card-answers">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
