import React from "react";
import "./backedby.css";
import gains from "../../assets/svg/gains.svg";
import ibcA from "../../assets/svg/ibcA.svg";
import ibcB from "../../assets/svg/ibcB.svg";
import fin from "../../assets/svg/fin.png";
import corum from "../../assets/svg/corum.png";
import redBelly from "../../assets/svg/redBelly.svg";
import wehod from "../../assets/svg/wehod.png";
import gritnova from "../../assets/svg/GRITNOVA 1.svg";
export const BackedBy = () => {
  return (
    <div className="backedBy-section container">
      <h2 className="backedBy-title">
        <span>Backed</span> By
      </h2>
      <div className="backedBy-container-logos">
        <div className="backedBy-container-logos-row-1">
          <div className="backedBy-container-logos-1">
            <img src={gritnova} alt="gritnova" className="backed-clip-logo-1" />
          </div>
          <div className="backedBy-container-logos-2">
            <img src={gains} alt="gains" className="backed-clip-logo-2" />
          </div>
          <div className="backedBy-container-logos-3">
            <img src={ibcA} alt="lbc" className="backed-clip-logo-3-a" />
            <img src={ibcB} alt="lbc" className="backed-clip-logo-3-b" />
          </div>
          <div className="backedBy-container-logos-4">
            <img src={fin} alt="finnovant" className="backed-clip-logo-4" />
          </div>
        </div>

        <div className="backedBy-container-logos-row-2">
          <div className="backedBy-container-logos-row-2-1">
            <img src={corum} alt="corum" className="backed-clip-logo-2-1" />
          </div>
          <div className="backedBy-container-logos-row-2-1">
            <img
              src={redBelly}
              alt="redBelly"
              className="backed-clip-logo-2-2"
            />
          </div>
          <div className="backedBy-container-logos-row-2-1">
            <img src={wehod} alt="wehod" className="backed-clip-logo-2-3" />
          </div>
        </div>
      </div>
    </div>
  );
};
