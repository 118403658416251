import React, { useEffect } from "react";
import NavbarSection from "../NavBar/Navbar.js";
import FooterSection from "../NewFooter/Footer.js";
import FeaturesSectionOne from "../InlinePages/features/SectionOneFeature/FeaturesSectionOne.js";

function NewFeature() {
  document.title = "3VO | Feature";

  useEffect(() => {
    // Function to handle auto-scrolling
    const handleAutoScroll = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          let offset = element.getBoundingClientRect().top;
          if (hash === "#tokenomics") {
            offset += 140; // Add additional offset for #advisors
          }
          window.scrollTo({
            top: window.pageYOffset + offset - 70, // Adjusted for header
            behavior: "smooth",
          });
        }
      }
    };

    handleAutoScroll();
    window.addEventListener("hashchange", handleAutoScroll);

    return () => {
      window.removeEventListener("hashchange", handleAutoScroll);
    };
  }, []);

  return (
    <div>
      <div className="bg-pages">
        <NavbarSection />
        <FeaturesSectionOne />
        <FooterSection />
      </div>
    </div>
  );
}

export default NewFeature;
