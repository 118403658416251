import React, { useState, useRef, useEffect } from "react";
import Counter from "../counter.js";
import "./NewHero.css";
import "./NewHero2.css";
import newLogo from "../../assets/svg/new-logo.svg";
// import VectorRight from "../../assets/svg/Vector-Right.svg";
// import VectorLeft from "../../assets/svg/Vector-Left.svg";
import Diamond from "../../assets/svg/hexagon-blue-min.svg";
// import Mockup from "../../assets/Hero/TwoPhones.png";
import Mockup from "../../assets/AISection/phone-hero1.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faBars } from "@fortawesome/free-solid-svg-icons";
import chatbotMobile from "../../assets/svg/chatbotMobile.png";
import NavbarSection from "../NavBar/Navbar.js";

const HeroPage = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const smoothScroll = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    document.querySelector("#get3voApp").scrollIntoView({
      behavior: "smooth",
      block: "start", // Aligns to the top of the section
    });
  };
  // Disable body scroll when mobile menu is open
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobileMenuOpen]);

  // Close mobile menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !event.target.closest(".header-burger-menu")
      ) {
        setIsMobileMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toggle mobile menu state
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  return (
    <div className="hero-page">
      <div className="overlap-wrapper">
        <div className="overlap">
          <NavbarSection />
          {/* <header className="header">
            <a href="https://3vo.me/">
              <img className="logo" alt="Union" src={newLogo} />
            </a>

            <div className="navbar">
              <div className="text-wrapper">Features</div>

            

              <a
                href="/newtoken "
                target=""
                rel="noreferrer"
                className="text-wrapper"
              >
                {" "}
                Token
              </a>

              <div className="text-wrapper">Resources</div>

              <a
                href="/newabout "
                target=""
                rel="noreferrer"
                className="text-wrapper"
              >
                {" "}
                About
              </a>
            </div>

            <div className="lunchapp">
              <div className="overlap-group">
                <a
                  href="https://play.google.com/store/apps/details?id=me.threevo.client"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="div">Launch App</div>
                </a>
              </div>
            </div>
          </header> */}
          {/* <div className="header-burger-menu">
            {isMobileMenuOpen ? (
              <>
                <FontAwesomeIcon
                  icon={faXmark}
                  className="header-burger-menu-icon"
                  onClick={toggleMobileMenu}
                />
              </>
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className="header-burger-menu-icon"
                id="close-button"
                onClick={toggleMobileMenu}
              />
            )}
          </div> */}
          {/* {isMobileMenuOpen && (
            <div ref={mobileMenuRef} className="header-mobile-menu">
              <div className="header-mobile-logo">
                <a href="/" target="" rel="noreferrer">
                  <img
                    src={newLogo}
                    className="header-innerlogo"
                    alt="Inner Logo"
                  />
                </a>
              </div>
              <div className="raper">
                <div className="submenu" text="Product">
                  Features
                </div>
                
                <div className="submenu" text="Product">
                  Token
                </div>
                <div className="submenu" text="Product">
                  Resources
                </div>
                <div className="submenu" text="Product">
                  About
                </div>
              </div>
            </div>
          )} */}

          <div className="frame-2">
            <div className="frame-3">
              <div className="frame-4">
                {/* <div className="text-wrapper-2">Join Our Community</div> */}

                <p className="the-future-of-social">The New Way We Internet</p>
              </div>

              <p className="the-platform-for-the">
                <div className="para">
                  Next-gen platform empowering everyone to tokenize communities,
                  leverage AI, and monetize influence with crypto
                </div>
              </p>
            </div>

            <div className="frame-5">
              <div className="overlap-group-wrapper">
                <div className="div-wrapper-1">
                  <div className="cta-color">
                    <a
                      href="https://forms.gle/mo8inCjMv8LDAtY3A"
                      alt="pre-sale"
                    >
                      <div className="text-wrapper-cta">Join Pre-Sale</div>
                    </a>
                  </div>
                </div>
                {/* <div className="div-wrapper">
                  <a href="https://forms.gle/mo8inCjMv8LDAtY3A" alt="pre-sale">
                    <div className="text-wrapper-3"></div>
                  </a>
                </div> */}
              </div>
              <div className="div-wrapper-2">
                <div className="cta-2">
                  <a
                    href="#get3voApp"
                    className="text-wrapper-cta"
                    onClick={smoothScroll}
                  >
                    Get 3VO
                  </a>
                </div>
              </div>
              {/* <div className="div-wrapper-2">
                <div className="cta-color">
                  <div className="text-wrapper-cta">Join Pre-Sale</div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <Counter targetNumber={10000} duration={0.1} /> */}
          {/* $<Counter targetNumber={75} duration={500} /> K */}

          {/* <div className="frame-6">
            <div className="frame-7">
              <div className="text-wrapper-4">10,000</div>

              <div className="text-wrapper-5"> Active Users</div>
            </div>

            <div className="frame-8">
              <div className="text-wrapper-4">2,000,000</div>

              <div className="text-wrapper-5">Interactions</div>
            </div>
          </div> */}
          {/* <div className="frame-6">
            <div className="frame-7">
              <div className="text-wrapper-4">
                <Counter start={10000} increment={1} interval={180000} />{" "}
                
              </div>
              <div className="text-wrapper-5">Active Users</div>
            </div>

            <div className="frame-8">
              <div className="text-wrapper-4">
                <Counter start={2000000} increment={1} interval={3000} />{" "}
                 
              </div>
              <div className="text-wrapper-5">Interactions</div>
            </div>
          </div> */}

          <div className="frame-6">
            <div className="frame-7">
              <div className="text-wrapper-4">
                <Counter
                  storageKey="activeUsers"
                  start={10000}
                  increment={1}
                  interval={180000}
                />
              </div>
              <div className="text-wrapper-5">Active Users</div>
            </div>

            <div className="frame-8">
              <div className="text-wrapper-4">
                <Counter
                  storageKey="interactions"
                  start={2000000}
                  increment={1}
                  interval={3000}
                />
              </div>
              <div className="text-wrapper-5">Interactions</div>
            </div>
          </div>

          <div className="overlap-2">
            <div className="frame-9">
              <div className="overlap-3">
                <img className="hexagon" alt="Hexagon" src={Diamond} />

                <img className="mockup" alt="mockup" src={Mockup} />

                {/* <div className="frame-10">
                  <div className="text-wrapper-6">Explore and Engage</div>

                  <img className="vector" alt="Vector" src={VectorRight} />
                </div> */}
              </div>
            </div>

            {/* <div className="frame-11">
              <div className="overlap-4">
                <div className="text-wrapper-7">Manage Your Assets</div>

                <img className="img" alt="Vector" src={VectorLeft} />
              </div>
            </div> */}
          </div>
          <img
            className="image-mobile"
            loading="lazy"
            src={chatbotMobile}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default HeroPage;
