import React from "react";
import "./DigitalSectionOne.css";

export const DigitalSectionOne = () => {
  return (
    // <div className="AboutSectionOne-wrapper container">
    //   <p className="about-title-text">About us</p>
    // </div>
    <div className="about-firt-frame container">
      <div className="about-first-reactangle"></div>
      <div className="about-second-reactangle">
        <p className="about-title">The problem with Web2</p>
        <p className="about-pargraph">
          The current system for creating, consuming, and transacting value is
          unfair and inefficient. Web2 platforms controlled by tech giants like
          Google and Amazon monopolize user data, while financial transactions
          rely on intermediaries like Visa and Mastercard. This concentration of
          power leads to censorship and manipulation.
        </p>
      </div>
    </div>
  );
};
export default DigitalSectionOne;
