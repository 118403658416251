// import React from "react";
// import "./signup.css";
// import claimNow from "../../assets/appscards/claimNow.svg";

// export const SignupSection = () => {
//   return (
//     <div className="signup-sec container">
//       <div className="signup-title-btn">
//         <div className="signup-title">
//           Sign Up Now for Your Chance to Win from a <span> 50,000$ </span> Prize
//           Pool
//         </div>
//         <div className="signup-frame">
//           <p className="signup-label-text">@username</p>
//           <img src={claimNow} alt="claimNow" className="claimNow" />
//         </div>
//         <div className="mobile-btn-sign">
//           <div className="signup-text-wrapper-cta">Claim Now</div>
//         </div>
//       </div>
//       <div className="signup-coins">
//         <div className="coins-img"></div>
//       </div>
//     </div>
//   );
// };
import React, { useState } from "react";
import "./signup.css";
import claimNow from "../../assets/appscards/claimNow.svg";

export const SignupSection = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);

  const handleClaimClick = () => {
    if (username.trim() === "") {
      setError(true); // Show error if empty
      return;
    }
    setError(false); // Clear error when valid
    window.location.href = `https://app.3vo.me/uid/${encodeURIComponent(
      username
    )}`;
  };

  return (
    <div className="signup-sec container">
      <div className="signup-title-btn">
        <div className="signup-title">
          Sign Up Now for Your Chance to Win from a <span>$50,000</span> Prize
          Pool
        </div>

        {/* Username Input with Error Handling */}
        <div className={`signup-frame ${error ? "error" : ""}`}>
          <input
            type="text"
            placeholder="Enter your username"
            className="signup-input"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setError(false); // Remove error when typing
            }}
          />
          <img
            src={claimNow}
            alt="Claim Now"
            className="claimNow"
            onClick={handleClaimClick}
          />
        </div>
        {/* Error Message */}
        {error && <p className="error-message">Username is required</p>}

        {/* Mobile Button */}
        <div className="mobile-btn-sign" onClick={handleClaimClick}>
          <div className="signup-text-wrapper-cta">Claim Now</div>
        </div>
      </div>

      {/* Background Coins */}
      <div className="signup-coins">
        <div className="coins-img"></div>
      </div>
    </div>
  );
};
