import React from "react";
import "./TokenFirstSection.css";
import { HashLink as Link } from "react-router-hash-link";
// import tokenCoins from "../../../../assets/svg/3vo-token.png";
export const TokenFirstSection = () => {
  return (
    <div className="TokenFirstSection-wrapper container">
      <div className="TokenFirstSection-background"></div>
      <div className="TokenFirstSection-container">
        <div className="TokenFirstSection-title-body">
          <h1 className="token-title-new">
            $3VO token
            {/* <img src={tokenCoins} alt="3vo-token" className="token-coins" /> */}
          </h1>
          <p className="token-sub">
            Liquidity for AI-Powered tokenized economies
          </p>

          <div className="token-btns">
            <div className="token-btn-one">
              <div className="token-btn-wrapper">
                <a href="https://forms.gle/mo8inCjMv8LDAtY3A" alt="pre-sale">
                  <div className="text-wrapper-cta-token">Join Pre-Sale</div>
                </a>
              </div>
            </div>

            <div className="token-btn-two">
              <div className="token-btn-wrapper">
                <Link smooth to="/#get3voApp" className="text-wrapper-cta">
                  <div className="text-wrapper-cta-token">Get 3VO</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
