import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import "./AiAgent.css";
import videoSvg from "../../assets/AiAgent/video.svg";
import videoImg from "../../assets/AiAgent/video.png";
import chatbotSvg from "../../assets/AiAgent/chatbot.svg";
import chatbotImg from "../../assets/AiAgent/chatbot.png";
import AnalyticsSvg from "../../assets/AiAgent/AnalyticsSvg.svg";
import AnalyticsImg from "../../assets/AiAgent/AnalyticsImg.png";
import musicSvg from "../../assets/AiAgent/musicSvg.svg";
import musicImg from "../../assets/AiAgent/musicImg.png";
import tradingSvg from "../../assets/AiAgent/tradingSvg.svg";
import tradingImg from "../../assets/AiAgent/Elon.png";
import GeneratorSvg from "../../assets/AiAgent/GeneratorSvg.svg";
import GeneratorImg from "../../assets/AiAgent/GeneratorImg.png";
import blackHair from "../../assets/AiAgent/blackhair.mp4";
import trump from "../../assets/AiAgent/TrumpCloser.mp4";
import elon from "../../assets/AiAgent/elon.mp4";
import blondGirl from "../../assets/AiAgent/blondGirl.mp4";
import tate from "../../assets/AiAgent/AnalyticsVideo.mp4";
import snoop from "../../assets/AiAgent/SD-SmokeDogg.mp4";

export const AiAgent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 450);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 450);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const videoRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [soundOnIndex, setSoundOnIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
    const video = videoRefs.current[index];
    if (video) {
      video.muted = true;
      video.play().catch((e) => console.error("Error playing video:", e));
    }
  };

  const handleMouseLeave = (index) => {
    setActiveIndex(null);
    const video = videoRefs.current[index];
    if (video) {
      video.pause();
    }
  };

  const handleClick = (index) => {
    const video = videoRefs.current[index];
    if (video) {
      if (soundOnIndex === index) {
        video.muted = true;
        setSoundOnIndex(null);
      } else {
        video.muted = false;
        setSoundOnIndex(index);
      }
    }
  };

  const videos = [
    { src: blackHair, title: "Video", img: videoImg, svg: videoSvg },
    { src: trump, title: "Chat Bot", img: chatbotImg, svg: chatbotSvg },
    {
      src: tate,
      title: "Analytics",
      img: AnalyticsImg,
      svg: AnalyticsSvg,
    },
    { src: snoop, title: "Music", img: musicImg, svg: musicSvg },
    { src: elon, title: "Trading", img: tradingImg, svg: tradingSvg },
    {
      src: blondGirl,
      title: "Image Generator",
      img: GeneratorImg,
      svg: GeneratorSvg,
    },
  ];

  return (
    <div className="AiAgent-wrapper container">
      {/* Title Section */}
      <motion.div
        className="AiAgnet-title-body"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <p className="AiAgent-title">
          Smarter profiles powered by <span>AI agents</span>
        </p>
      </motion.div>

      {/* Cards Section */}

      <motion.div
        className="AiAgnet-Ai-cards-wrapper"
        initial="hidden"
        whileInView="visible"
        variants={{
          hidden: {},
          visible: { transition: { staggerChildren: 0.2 } },
        }}
        viewport={{ once: true }}
        style={{
          transform: `translateX(-${currentIndex * 100}%)`, // Moves all cards
          transition: "transform 0.5s ease-in-out", // Smooth animation
        }}
      >
        {videos.map((video, index) => (
          <motion.div
            key={index}
            className={`AiAgent-Ai-card ${
              activeIndex === index ? "video-active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            onClick={() => handleClick(index)}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            viewport={{ once: true }}
          >
            <div className="AiAgent-Ai-card-title">
              <img src={video.svg} className="ai-agent-svg" alt="ai-agent" />
              <p className="Ai-card-title">{video.title}</p>
            </div>

            <div className="AiAgent-video-container">
              {activeIndex === index ? (
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  src={video.src}
                  className="ai-agent-video"
                  loop
                  muted
                  onCanPlay={(e) => e.target.play()}
                />
              ) : (
                <img
                  src={video.img}
                  className="ai-agent-img"
                  alt={video.title}
                />
              )}
              {activeIndex !== index && <p className="hover-text">Play</p>}
              {soundOnIndex === index && (
                <p className="sound-indicator">🔊 Sound On</p>
              )}
            </div>
          </motion.div>
        ))}
      </motion.div>
      {isMobile && (
        <>
          <button
            className="AiAgent-slider-btn leftb"
            onClick={() =>
              setCurrentIndex((prev) =>
                prev > 0 ? prev - 1 : videos.length - 1
              )
            }
          >
            ‹
          </button>
          <button
            className="AiAgent-slider-btn rightb"
            onClick={() =>
              setCurrentIndex((prev) =>
                prev < videos.length - 1 ? prev + 1 : 0
              )
            }
          >
            ›
          </button>
        </>
      )}

      {/* Bottom Section */}
      <motion.div
        className="ai-agent-bottom"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        viewport={{ once: true }}
      >
        <div className="ai-agent-paragraph">
          <p className="AiAent-cta">
            Create personalized AI companions that reflect your unique style,
            automate content creation and page management, and even interact
            with your followers.
          </p>
          <p className="AiAent-cta">
            Hire a team of specialized agents from a thriving marketplace of
            unique AI personalities, and revolutionize how you connect with your
            audience.
          </p>
        </div>

        <motion.div
          className="meet-btn"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.7 }}
          viewport={{ once: true }}
        >
          <a href="/newfeature">
            <p className="meet-btn-text">Meet AI 3VOlutionaries</p>
          </a>
        </motion.div>
      </motion.div>
      <div className="AiAgent-btn-sign">
        <a href="/newfeature">
          <div className="AiAgent-text-wrapper-cta">Meet AI 3VOlutionaries</div>
        </a>
      </div>
    </div>
  );
};
