import React, { useEffect, useRef, useState } from "react";
export const AnimatedTitle = ({ children }) => {
  const titleRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: document.querySelector(".scrollable-container"), // Set your scrollable container if applicable
      threshold: 0.1,
    }
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing after it becomes visible
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
        rootMargin: "0px 0px -100px 0px", // Trigger slightly earlier if needed
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <div ref={titleRef} className={`titles ${isVisible ? "visible" : ""}`}>
      {children}
    </div>
  );
};
