import React from "react";
import "./MissonSection.css";

export const MissonSection = () => {
  return (
    <div className="Mission-firt-frame container">
      <div className="mission-rectangle">
        <div className="mission-content">
          <div className="mission-title-wrapper">
            <p className="mission-sub">
              A Global Permissionless Marketplace of Value
            </p>
            <h2 className="mission-title">Vision</h2>
          </div>
          <ul className="mission-points">
            <li>
              <span className="dot">•</span> Decentralization and User
              Empowerment
            </li>
            <li>
              <span className="dot">•</span> Democratization of Opportunities
            </li>
            <li>
              <span className="dot">•</span> Enhanced Privacy and Security
            </li>
            <li>
              <span className="dot">•</span> Collaborative and Interconnected
              Ecosystem
            </li>
            <li>
              <span className="dot">•</span> Global Adoption and Impact
            </li>
          </ul>
        </div>
      </div>
      <div className="mission-rectangle">
        <div className="mission-content">
          <p className="mission-sub-text">
            We are striving for a future where Web3 technologies revolutionize
            the digital landscape, transforming the way individuals, businesses,
            and organizations interact and engage online.
          </p>
          <div className="mission-title-wrapper">
            <p className="mission-sub">
              Empowering every individual, business and organization with web3
              tools
            </p>
            <h2 className="mission-title">Mission</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MissonSection;
