import React, { useState } from "react";
import "./Footer.css";
import { FaChevronDown } from "react-icons/fa"; // Import arrow icon
import Logo3vo from "../../assets/svg/3voLogo.svg";

export const FooterSection = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  // List of links with disabled status
  const footerSections = [
    {
      title: "Project",
      links: [
        { name: "About", url: "/newabout", disabled: false },
        { name: "Features", url: "/newfeature", disabled: false },
        { name: "Token", url: "/newtoken", disabled: false },
        { name: "Careers", url: "/careers", disabled: true }, // Disabled
        { name: "Usecases", url: "/usecases", disabled: true },
      ],
    },
    {
      title: "Product",
      links: [
        { name: "3VO Hub", url: "https://app.3vo.me", disabled: false },
        {
          name: "3VO Mobile",
          url: "https://play.google.com/store/apps/details?id=me.threevo.client",
          disabled: false,
        }, // Disabled
        {
          name: "3VOgram",
          url: "https://t.me/mysteryBox3voBot",
          disabled: false,
        },
      ],
    },
    {
      title: "Resources",
      links: [
        { name: "Docs", url: "https://docs.3vo.me", disabled: false },
        { name: "Blog", url: "https://blog.3vo.me/", disabled: false },
        { name: "Whitepaper", url: "/#whitepaper", disabled: true },
        { name: "PitchDeck", url: "/#pitchdeck", disabled: false },
        { name: "Tokenomics", url: "/#tokenomics", disabled: false }, // Disabled
      ],
    },
    {
      title: "Documentation",
      links: [
        { name: "Privacy Policy", url: "/#Privacy", disabled: false },
        { name: "KYC/AML policy", url: "#KYC", disabled: false },
        { name: "Terms of use", url: "#Terms", disabled: false },
      ],
    },
    {
      title: "Forms:",
      links: [
        { name: "Support", url: "/#Support", disabled: false },
        { name: "Partnership", url: "/#Partnership", disabled: false },
        { name: "Verification", url: "/#Verification", disabled: false },
        { name: "Token Sale", url: "/#Token-sale", disabled: false }, // Disabled
      ],
    },
    {
      title: "Contact:",
      links: [
        { name: "Email", url: "/#3vo@gmail.com", disabled: false },
        { name: "Support", url: "/#Support", disabled: false },
      ],
    },
  ];

  return (
    <div className="Footer-wrapper">
      <div className="Footer-section-logos">
        <img src={Logo3vo} className="Footer-logo" alt="3voLogo" />
        <div className="Footer-Sections">
          {footerSections.map((section, index) => (
            <div key={index} className="Footer-Sections-Columns">
              <button
                className={`Footer-column-title ${
                  openSection === index ? "open" : ""
                }`}
                onClick={() => toggleSection(index)}
              >
                {section.title}
                <FaChevronDown
                  className={`arrow ${openSection === index ? "open" : ""}`}
                />
              </button>
              <div
                className={`Footer-column-section-wrapper ${
                  openSection === index ? "open" : ""
                }`}
              >
                {section.links.map((link, i) => (
                  <div key={i} className="Footer-column-section">
                    {link.disabled ? (
                      <span className="Footer-column-link disabled">
                        {link.name}
                      </span>
                    ) : (
                      <a href={link.url} className="Footer-column-link">
                        {link.name}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <p className="Right-Reserve-text">© 2025 3VO S.A. All Rights Reserved.</p>
    </div>
  );
};
export default FooterSection;
