import React, { useEffect } from "react";
import NavbarSection from "../NavBar/Navbar.js";
import AboutSectionOne from "../InlinePages/about/AboutSectionOne.js";
import DigitalSectionOne from "../InlinePages/about/secondAbout/DigitalSectionOne.js";
import FooterSection from "../NewFooter/Footer.js";
import BridgingSection from "../InlinePages/about/ThreeAbout/BridgingSection.js";
import MissonSection from "../InlinePages/about/FourAbout/MissonSection.js";
import TeamSection from "../InlinePages/about/FifthAbout/TeamSection.js";
import RoadmapSection from "../InlinePages/about/sixAbout/RoadmapSection.js";
import { AdvisorsSection } from "../InlinePages/about/AdvisorsSection/AdvisorsSection.js";

function NewAbout() {
  //   const { theme } = useTheme();

  document.title = "3VO | About";

  useEffect(() => {
    // Function to handle auto-scrolling
    const handleAutoScroll = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          let offset = element.getBoundingClientRect().top;
          if (hash === "#tokenomics") {
            offset += 140; // Add additional offset for #advisors
          }
          window.scrollTo({
            top: window.pageYOffset + offset - 70, // Adjusted for header
            behavior: "smooth",
          });
        }
      }
    };

    handleAutoScroll();
    window.addEventListener("hashchange", handleAutoScroll);

    return () => {
      window.removeEventListener("hashchange", handleAutoScroll);
    };
  }, []);

  return (
    <div>
      <div className="bg-pages">
        <NavbarSection />
        <AboutSectionOne />
        <DigitalSectionOne />
        <BridgingSection />
        <MissonSection />
        <TeamSection />
        <AdvisorsSection />
        <RoadmapSection />
        <FooterSection />
      </div>
    </div>
  );
}

export default NewAbout;
