import React from "react";
import "./partners.css";
import skillful from "../../assets/svg/Skillful-grey.svg";
import dots from "../../assets/svg/3Dots-grey.svg";
import Trireme from "../../assets/svg/trireme-grey.svg";
import stepIn from "../../assets/svg/Step-in-grey.svg";
import arina from "../../assets/svg/arina.png";
import whiteBridge from "../../assets/svg/WhiteBridge-grey.svg";
import schooles from "../../assets/svg/Scholes-grey.svg";
import dmany from "../../assets/svg/Dmany-grey.svg";
import launch from "../../assets/svg/LaunchPunks-grey.svg";
import amplifi from "../../assets/svg/amplifi-grey.svg";
import chosty from "../../assets/svg/Ghostycash-grey.svg";
import onePredict from "../../assets/svg/Onepredict-grey.svg";
import rouge from "../../assets/svg/Rogue-grey.svg";
import trailblaze from "../../assets/svg/trailblaze-grey.svg";
import starlight from "../../assets/svg/Starlight-grey.svg";

import chainWare from "../../assets/svg/chainAware.svg";
import emoney from "../../assets/svg/EMoney.svg";
import chainGpt from "../../assets/svg/ChainGpt.svg";
import helicode from "../../assets/svg/Helicode.svg";
import klink from "../../assets/svg/Klink.svg";
export const Partners = () => {
  return (
    <div className="partners-section container">
      <h2 className="partners-title">Partners</h2>

      <div className="partner-container-slider">
        <div className="partner-container-slider-1">
          <div className="partner-slider-inside">
            <img src={skillful} alt="skillful" className="partner-skillful" />
            <img src={dots} alt="3Dots" className="partner-3Dots" />
            <img src={Trireme} alt="Trireme" className="partner-Trireme" />
            <img src={stepIn} alt="stepIn" className="partner-stepIn" />
            <img src={arina} alt="arina" className="partner-arina" />
            {/* todo */}
            <img src={chainWare} alt="chainWare" className="partner-skillful" />
            <img src={emoney} alt="emoney" className="partner-3Dots" />
            <img src={chainGpt} alt="chainGpt" className="partner-Trireme" />
            <img src={helicode} alt="helicode" className="partner-stepIn" />
            <img src={klink} alt="klink" className="partner-arina" />
          </div>
        </div>
        <div className="partner-container-slider-2">
          <div className="partner-slider-inside-2">
            <img
              src={whiteBridge}
              alt="whiteBridge"
              className="partner-whiteBridge"
            />
            <img src={schooles} alt="schooles" className="partner-schooles" />
            <img src={dmany} alt="dmany" className="partner-dmany" />
            <img src={launch} alt="launchpunks" className="partner-launch" />
            <img src={amplifi} alt="amplifi" className="partner-amplifi" />
            <img src={chosty} alt="chostyCash" className="partner-chosty" />
            <img
              src={onePredict}
              alt="onePredict"
              className="partner-onePredict"
            />
            <img src={rouge} alt="rouge" className="partner-rouge" />
            <img
              src={trailblaze}
              alt="trailblaze"
              className="partner-trailblaze"
            />
            <img
              src={starlight}
              alt="starlight"
              className="partner-starlight"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
