import React from "react";
import "./App.css";
import { useTheme } from "./ThemeProvider.js";
import HeroPage from "./components/NewHero/NewHero.js";
import { ProfitSection } from "./components/Profit/profit.js";
import { BrandSection } from "./components/BrandSection/brandSection.js";
import { EngagementSection } from "./components/engagment/engagement.js";
import { AppsSections } from "./components/3voApps/3voApps.js";
import { SignupSection } from "./components/signup/signup.js";
import { FaqSection } from "./components/faq/faqSection.js";
import { TradeToken } from "./components/tradeToken/tradeToken.js";
import { AiAgent } from "./components/AiAgent/AIAgent.js";
import { BackedBy } from "./components/backedBy/backedby.js";
import { Partners } from "./components/partners/partners.js";
import { JoinCommunity } from "./components/JoinCommunity/JoinCommunity.js";
import { Tokenomics } from "./components/3voToken/3voToken.js";
import { FooterSection } from "./components/NewFooter/Footer.js";
// import { Join3vo } from "./components/join3vo/join3vo.js";
// import Header from "./components/header/mainHeader.js";
// import Hero from "./components/hero/mainHero.js";
// import Statement from "./components/statement/mainStatement.js";
// import Decentralized from "./components/decentralized/mainDecentralized.js";
// import Features from "./components/features/mainFeatures.js";
// import Usecases from "./components/usecases/mainUsecases.js";
// import Powered from "./components/powered/mainPowered.js";
// import Token from "./components/token/mainToken.js";
// import Backed from "./components/backed/mainBacked.js";
// import Cards from "./components/cards/mainCards.js";
// import CallToAction from "./components/calltoaction/mainCallToAction.js";
// import Footer from "./components/footer/mainFooter.js";
// import { AISection, ThridFrame } from "./components/AISection/AISection.js";
// import { EarningSection } from "./components/earning/earningSection.js";

function App() {
  const { theme } = useTheme();

  return (
    <div className={`App ${theme === "light" ? "" : "dark-theme"}`}>
      <HeroPage />
      <ProfitSection />
      <TradeToken />
      <AiAgent />
      <BrandSection />
      <EngagementSection />
      <Tokenomics />
      <AppsSections />
      <BackedBy />
      <Partners />
      <SignupSection />
      <JoinCommunity />
      <FaqSection />
      <FooterSection />

      {/* <EarningSection /> */}
      {/* <Header />
      <Hero />
      <Statement /> */}
      {/* <Decentralized />
      <Features />
      <Usecases />
      <Powered />
      <Token />
      <Backed />
      <Cards />
      <CallToAction />
      <Footer /> */}
    </div>
  );
}

export default App;
