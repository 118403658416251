import React, { useEffect, useRef, useState } from "react";
import "../../components/Profit/profit.css";
import btn from "../../assets/svg/button-sec-2.svg";
import pages from "../../assets/AISection/pages.png";
import botAnime from "../../assets/AISection/bot-animie.gif";
import bars from "../../assets/AISection/participtate-gif-2.gif";
import Tokens from "../../assets/AISection/tokens.png";
import ai from "../../assets/Hero/AI.png";
import token from "../../assets/AISection/tokens-tag.png";
import quests from "../../assets/AISection/guests.png";
import pagess from "../../assets/AISection/pagess.png";
// import chatbotMobile from "../../assets/svg/chatbot-mobile.svg";
import btnM from "../../assets/svg/join-presale-mobile.svg";
import { AnimatedTitle } from "../myUitls/AnimatedTitle.js";
import { AnimatedText } from "../myUitls/AnimatedText.js";
// import post from "../../assets/AISection/AI.png";
// import image from "../../assets/Hero/image.png";
// import bars from "../../assets/AISection/Participate4.png";
// import comments from "../../assets/Hero/comments.png";
// import wallet from "../../assets/Hero/wallet.png";
// import transcat from "../../assets/Hero/Transact.png";
// import connect from "../../assets/Hero/connect.png";
export const ProfitSection = () => {
  return (
    <>
      {/* <div className="between-wrapper">
         
        <img className="image-mobile" src={chatbotMobile} alt="" />
      </div> */}
      <div className="second-section container">
        <div className="left-part">
          <div className="left-wrapper">
            <div className="wrapper-title">
              <AnimatedTitle>Turn Passion Into Profits</AnimatedTitle>
            </div>
            <div className="wrapper-paragraph">
              <div className="profit-paragraph">
                <AnimatedText>
                  The New Internet is where value flows freely and AI empowers
                  everyone. It's a world where creators are rewarded,
                  communities flourish, and the power of the internet is truly
                  democratized.
                </AnimatedText>
              </div>

              <div className="explor-btn-wapper">
                <AnimatedBtn>
                  <a href="/newfeature">
                    <img className="btn-1" alt="explore-3vo" src={btn} />
                  </a>
                  <div className="mobile-btn-sign-l">
                    <a href="/newfeature">
                      <div className="signup-text-wrapper-cta-l">
                        Explore 3VO
                      </div>
                    </a>
                  </div>
                  {/* <div>
                    <img className="btn-m" alt="explore-3vo" src={btnM} />
                  </div> */}
                </AnimatedBtn>
              </div>
            </div>
          </div>
        </div>
        {/* <AnimatedBtn> */}
        <div className="animation-content">
          <Animation></Animation>
        </div>
        {/* </AnimatedBtn> */}
      </div>
    </>
  );
};

// const AnimatedTitle = ({ children }) => {
//   const titleRef = useRef(null);
//   const [isVisible, setIsVisible] = useState(false);
//   const observer = new IntersectionObserver(
//     (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//           observer.unobserve(entry.target);
//         }
//       });
//     },
//     {
//       root: document.querySelector(".scrollable-container"), // Set your scrollable container if applicable
//       threshold: 0.1,
//     }
//   );

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setIsVisible(true);
//             observer.unobserve(entry.target); // Stop observing after it becomes visible
//           }
//         });
//       },
//       {
//         threshold: 0.1, // Trigger when 10% of the element is visible
//         rootMargin: "0px 0px -100px 0px", // Trigger slightly earlier if needed
//       }
//     );

//     if (titleRef.current) {
//       observer.observe(titleRef.current);
//     }

//     return () => {
//       if (titleRef.current) {
//         observer.unobserve(titleRef.current);
//       }
//     };
//   }, []);

//   return (
//     <div ref={titleRef} className={`titles ${isVisible ? "visible" : ""}`}>
//       {children}
//     </div>
//   );
// };
// const AnimatedText = ({ children }) => {
//   const textRef = useRef(null);
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setIsVisible(true);
//             observer.unobserve(entry.target); // Stop observing once visible
//           }
//         });
//       },
//       { threshold: 0.1 } // Trigger when 10% of the paragraph is visible
//     );

//     if (textRef.current) {
//       observer.observe(textRef.current);
//     }

//     return () => {
//       if (textRef.current) {
//         observer.unobserve(textRef.current);
//       }
//     };
//   }, []);

//   return (
//     <div ref={textRef} className={`right-text ${isVisible ? "visible" : ""}`}>
//       {children}
//     </div>
//   );
// };

const AnimatedBtn = ({ children }) => {
  const textRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing once visible
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the paragraph is visible
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <p ref={textRef} className={`btn-wrapper ${isVisible ? "visible" : ""}`}>
      {children}
    </p>
  );
};
const Animation = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 2000); // Change every 2 seconds
    return () => clearInterval(interval);
  }, []);

  const images = [botAnime, pages, Tokens, bars];
  const tags = [
    { className: "Ai", src: ai },
    { className: "Pages", src: pagess },
    { className: "Tokens", src: token },
    { className: "Quests", src: quests },
    // { className: "transcat", src: transcat },
  ];

  return (
    <div className="right">
      {images.map((image, index) => (
        <img
          key={index}
          className={`main-image ${activeIndex === index ? "active" : ""}`}
          src={image}
          alt={`Main ${index + 1}`}
        />
      ))}
      <div className="tags">
        {tags.map((tag, index) => (
          <img
            key={index}
            className={`tag ${tag.className} ${
              activeIndex === index ? "active" : ""
            }`}
            src={tag.src}
            alt={tag.className}
          />
        ))}
      </div>
    </div>
  );
};

export default Animation;
