import React, { useEffect } from "react";
// import "./embed.css";
import NavbarSection from "../NavBar/Navbar.js";
import FooterSection from "../NewFooter/Footer.js";
import { TokenFirstSection } from "../InlinePages/token/firstSection/firstSection.js";
import { TokenSecondSection } from "../InlinePages/token/secondSection/TokenSecondSection.js";
import { TokenThridSection } from "../InlinePages/token/thridSectionToken/TokenThridSection.js";
import { TokenFourSection } from "../InlinePages/token/fourSection/TokenFourSection.js";
import { SectionFiveToken } from "../InlinePages/token/sectionFiveToken/SectionFiveToken.js";
// import { useTheme } from "../ThemeProvider";
// import TokenHero from "../token/tokenHero/tokenHero.js";
// import TokenStatement from "../token/tokenStatement/tokenStatement.js";
// import TokenUtility from "../token/tokenUtility/tokenUtility.js";
// import Tokenomics from "../token/tokenomics/tokenomics.js";
// import TokenPresale from "../token/tokenPresale/tokenPresale.js";
// import TokenCall from "../token/tokenCall/tokenCall.js";

function NewToken() {
  //   const { theme } = useTheme();

  document.title = "3VO | Token";

  useEffect(() => {
    // Function to handle auto-scrolling
    const handleAutoScroll = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          let offset = element.getBoundingClientRect().top;
          if (hash === "#tokenomics") {
            offset += 140; // Add additional offset for #advisors
          }
          window.scrollTo({
            top: window.pageYOffset + offset - 70, // Adjusted for header
            behavior: "smooth",
          });
        }
      }
    };

    handleAutoScroll();
    window.addEventListener("hashchange", handleAutoScroll);

    return () => {
      window.removeEventListener("hashchange", handleAutoScroll);
    };
  }, []);

  return (
    <div>
      <div className="bg-pages">
        {/* <NavbarSection /> */}

        <NavbarSection />
        <TokenFirstSection />
        <TokenSecondSection />
        <TokenThridSection />
        <TokenFourSection />
        <SectionFiveToken />
        <FooterSection />
      </div>
    </div>
  );
}

export default NewToken;
